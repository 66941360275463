@import '../../styles/variables';

.user-select {
  .MuiMenuItem-root {
    padding: 5px;
    gap: 5px;

    .user-info {
      h5 {
        margin: -5px 0;
      }

      p {
        margin: -3px 0;
        font-size: 10px;
        color: gray;
        text-transform: capitalize;
      }
    }
  }

  .none {
    justify-content: center;
  }
}
