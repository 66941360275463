@import '../../styles/variables';

.campaign {
  margin: 0 auto;
  width: 90%;

  .split-view-panel.left-panel & {
    width: 95%;
  }

  .wizard-button {
    .MuiFab-root {
      background-color: $primary-color;
      position: fixed;
      right: 25px;
      bottom: 25px;

      .MuiSvgIcon-root {
        color: white;
      }
    }
  }

  .campaign-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $primary-color;
    padding: 10px 0;
    margin-bottom: 15px;
    position: sticky;
    top: 65px;
    z-index: 10;
    background-color: white;

    .patient-info {
      display: flex;
      align-items: center;

      .line {
        border-left: 1px solid $primary-color;
        margin: 0 15px;
        height: 100%;
      }

      p {
        margin: 0 10px 0 0;
        display: flex;
        align-items: center;

        b {
          margin-right: 5px;
        }

        .MuiSvgIcon-root {
          margin-left: 5px;
        }
      }
    }

    .actions {
      display: flex;
      gap: 20px;

      .MuiButton-contained {
        color: white;
        text-transform: none;
        height: 25px;
        background-color: $primary-color;
      }

      .tag.empty {
        background-color: $primary-color;
      }

      .tag.saved {
        background-color: $tagged-color;
      }

      .MuiIconButton-root {
        padding: 0;

        .MuiSvgIcon-root {
          height: 25px;
          width: 25px;
        }
      }
    }
  }

  .section {
    margin-bottom: 30px;

    h3 {
      margin: 0 0 20px 0;
      font-size: 20px;
    }

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      h3 {
        margin: 0;
      }
    }
  }

  .info {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;

    .MuiFormControl-root {
      .MuiInputBase-root {
        height: 40px;
        min-width: 200px;
      }
    }

    p {
      margin: 0;
    }

    .provider {
      min-width: 200px;
    }

    .status {
      min-width: 200px;
    }
  }

  .diagnoses-payers-comments {
    .header {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      gap: 10px;

      h3 {
        margin: 0;
      }

      .MuiIconButton-root {
        padding: 5px;
        color: $primary-color;
      }
    }

    .categories {
      display: flex;

      .category {
        margin-right: 25px;

        h4 {
          margin: 0;
        }
      }
    }
  }

  .discharge-details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;

    h3 {
      font-size: 16px;
      font-weight: 500;
      color: #6b7280;
      margin-bottom: 10px;
    }

    .info-table {
      border: 1px solid #e5e7eb;
      border-radius: 8px;
      overflow: hidden;
      min-width: 200px;
      max-width: 500px;

      table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;

        thead {
          background-color: rgba(229, 231, 235, 0.7);

          th {
            padding: 10px;
            font-size: 14px;
            font-weight: 500;
            text-align: left;
          }
        }

        tbody {
          tr {
            &:nth-of-type(even) {
              background-color: rgba(229, 231, 235, 0.5);
            }

            td {
              padding: 10px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .contact-attempts {
    .header {
      display: flex;
      align-items: center;
      gap: 5px;
      margin-bottom: 20px;
      height: 50px;

      h3 {
        margin: 0;
      }

      .MuiButtonBase-root {
        color: $primary-color;
        border-color: $primary-color;
        text-transform: none;
        padding: 5px;

        .MuiSvgIcon-root {
          color: $primary-color;
          height: 35px;
          width: 35px;
        }
      }

      .loader {
        padding: 5px;
      }
    }

    .add {
      height: 25px;
      width: 25px;
    }

    .add:hover {
      color: $primary-color;
      cursor: pointer;
    }

    .add.single {
      padding-top: 10px;
    }

    .attempt {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 10px;
      padding: 10px 0;

      .MuiSvgIcon-root {
        color: $primary-color;
      }

      .MuiInputBase-root {
        height: 35px;
      }

      .type {
        width: 100px;

        fieldset {
          top: -12px;

          legend {
            color: gray;
            padding-bottom: 10px;

            span {
              opacity: 1;
            }
          }
        }

        &.Mui-disabled {
          .MuiSelect-select {
            -webkit-text-fill-color: black;
          }

          .MuiSelect-icon {
            display: none;
          }
        }
      }

      .date-time-picker {
        .MuiInputBase-root {
          fieldset {
            top: -7px;
          }
        }
      }

      .actions {
        display: flex;
        align-items: center;
      }
    }
  }

  .referred-providers {
    .providers-button {
      color: $primary-color;
      text-transform: none;
      padding: 0;
      margin-left: 15px;
    }

    .provider {
      display: flex;
      gap: 15px;
      border-bottom: 1px solid lightgray;
      padding: 10px 0;
      align-items: center;

      .provider-info {
        .names {
          margin-bottom: 10px;

          .name {
            font-weight: bold;

            span {
              font-weight: normal;
              color: gray;
            }
          }

          .specialty {
            color: gray;
          }
        }

        .status {
          display: flex;
          align-items: center;

          .MuiFormControl-root {
            .MuiInputBase-root {
              height: 40px;
              min-width: 200px;
            }
          }

          .warning {
            color: orange;
            margin: 5px;
            height: 25px;
            width: 25px;
          }
        }
      }

      .departments {
        display: flex;
        gap: 10px;

        .address {
          font-size: 14px;

          .department {
            font-weight: bold;

            p {
              margin: 0;
              font-weight: normal;
              color: gray;
            }
          }
        }
      }
    }
  }

  .patient-appointments {
    .header {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      gap: 10px;

      h3 {
        margin: 0;
      }

      .MuiIconButton-root {
        padding: 5px;
        color: $primary-color;
      }
    }

    .MuiTableContainer-root {
      border: 1px solid black;
      border-radius: 10px;
    }
  }

  .appointments {
    .header {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      gap: 5px;

      h3 {
        margin: 0;
      }

      .Mui-disabled {
        color: gray;
      }
    }

    .summary {
      width: 75%;
      margin-bottom: 10px;

      .MuiTextField-root {
        width: 100%;

        .MuiInputLabel-root {
          color: $primary-color;
        }

        .MuiInputBase-root {
          padding: 10px;
        }

        .MuiInputBase-input.Mui-disabled {
          -webkit-text-fill-color: black;
        }

        .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
          border-color: $primary-color;
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;

      .new-appointment {
        width: 200px;
        background-color: $primary-color;
        text-transform: none;
        margin: 25px auto;
      }

      .MuiTableCell-root {
        &.gray {
          color: gray;
        }

        .MuiButtonBase-root {
          height: 25px;
          width: 100px;
        }
      }

      .cancel {
        color: orange;
        background-color: white;
        border: 1px solid orange;
      }

      .MuiTableContainer-root {
        border: 1px solid black;
        border-radius: 10px;

        .MuiTableCell-root {
          padding: 10px;
        }
      }

      .slots {
        .MuiTableContainer-root {
          max-height: 400px;

          .MuiTableRow-root:nth-child(even) {
            background-color: rgb(240, 240, 240);
          }

          .MuiButtonBase-root {
            background-color: $primary-color;
            color: white;
            text-transform: none;
          }

          .copy:hover {
            color: $primary-color;
            cursor: pointer;
          }

          .copy:active {
            color: black;
          }
        }
      }
    }

    .filters {
      margin: 25px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 25px;

      button {
        color: $primary-color;
        border-color: $primary-color;
        text-transform: none;
        height: 40px;

        p {
          margin: 5px;
        }
      }

      .specialty-filter {
        span {
          color: black;
          margin-left: 5px;
        }
      }

      .department-filter {
        span {
          color: black;
          margin-left: 5px;
        }
      }

      :disabled {
        color: gray;
        border-color: gray;
      }

      .provider-filter {
        button {
          width: 150px;
        }

        span {
          color: black;
          margin-left: 5px;
        }
      }

      .appointment-type-filter {
        button {
          min-width: 200px;
        }

        span {
          color: black;
          margin-left: 5px;
        }
      }

      .date-filters {
        .MuiInputBase-root {
          height: 40px;
          width: 150px;
        }

        label {
          color: $primary-color;
        }

        fieldset {
          border-color: $primary-color;
        }
      }
    }
  }

  .notes {
    .header {
      display: flex;
      align-items: center;
      gap: 5px;

      h3 {
        margin: 0;
      }
    }

    .MuiTextField-root {
      width: 100%;
    }

    .MuiInputBase-root {
      height: 200px;
    }
  }

  .csat {
    display: flex;
    align-items: flex-end;
    gap: 20px;

    .csat-rating {
      .MuiTypography-root {
        margin-left: 2px;
      }
    }

    .csat-comments {
      width: 100%;
    }
  }

  .message-history {
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h3 {
        margin: 0;
      }

      .header-options {
        display: flex;
        align-items: center;
        gap: 5px;

        .MuiSvgIcon-root.status {
          height: 30px;
          width: 30px;
        }

        .MuiButtonBase-root {
          .MuiSvgIcon-root {
            color: $primary-color;
            height: 35px;
            width: 35px;
          }
        }

        .loader {
          height: auto;
          padding: 5px;
        }
      }

      .campaign-number {
        width: 175px;
        height: 35px;
      }
    }

    .messages {
      border: 1px solid black;
      box-shadow: 0 2.5px 5px 0 rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      height: 400px;
      overflow: auto;

      .message {
        border: 1px solid black;
        border-radius: 10px;
        margin: 10px auto;
        padding: 10px;

        .meta-info {
          border: 1px solid $primary-color;
          border-radius: 5px;
          margin-top: 10px;

          .MuiAccordion-root {
            min-height: 35px;
            box-shadow: none;

            .MuiAccordionSummary-root {
              min-height: 35px;
            }
          }

          .MuiAccordion-root::before {
            background-color: white;
          }

          .MuiAccordionSummary-content {
            font-weight: 500;
            margin: 5px 0;
          }

          .MuiAccordionDetails-root {
            padding: 5px 15px;

            .body {
              display: flex;
              flex-direction: column;
              overflow: scroll;
              max-height: 200px;
              white-space: pre;
            }

            .body.collapse {
              height: 150px;
              overflow: scroll;
            }
          }

          .recommended {
            .loader {
              height: max-content;
            }

            .text {
              white-space: pre-wrap;
            }

            .translation {
              font-style: italic;
              margin: 10px 0;
            }

            .actions {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              gap: 15px;
              margin-top: 5px;
              height: 35px;
              padding: 0;

              .MuiButtonBase-root:hover.clicked:hover {
                color: gray;
              }

              .MuiButtonBase-root {
                background-color: white;
                width: 20px;
                height: 20px;

                .MuiSvgIcon-root {
                  width: 20px;
                  height: 20px;
                }
              }

              .MuiButtonBase-root.green {
                color: $primary-color;
              }

              .MuiButtonBase-root:hover {
                color: $primary-color;
              }

              .MuiChip-root {
                width: fit-content;
              }

              .MuiChip-root:hover {
                border-color: $primary-color;
              }

              .MuiPaper-root {
                padding: 0 10px;
                height: 35px;

                .MuiSvgIcon-root {
                  width: 20px;
                  height: 20px;
                }
              }
            }
          }
        }

        &.ai {
          border-color: $primary-color;
          margin: 1% 2.5% 1% 15%;
        }

        &.coordinator {
          border-color: orange;
          margin: 1% 2.5% 1% 15%;
        }

        &.patient {
          margin: 1% 15% 1% 2.5%;
        }

        .message-header {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;

          .actor {
            font-weight: bold;
          }

          .time {
            color: gray;
          }

          .ai {
            color: $primary-color;
          }

          .patient {
            color: black;
          }

          .coordinator {
            color: orange;
          }

          .function {
            color: lightslategray;
          }

          .MuiButtonBase-root {
            padding: 0;
          }

          span {
            color: gray;
          }
        }

        .body {
          .msg {
            white-space: pre-wrap;
            display: flex;
            justify-content: space-between;
          }

          .green {
            color: $primary-color;
          }

          .orange {
            color: orange;
          }

          .red {
            color: red;
          }

          &.function {
            display: flex;
            justify-content: space-evenly;
          }

          .translation {
            margin: 10px 0;
            font-style: italic;
          }
        }

        .message-action {
          border: 1px solid lightgray;
          border-radius: 5px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .info {
            padding-left: 10px;
            display: flex;
            gap: 15px;

            .slot {
              display: grid;
              grid-template-columns: 1.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
              gap: 25px;
            }

            .quality-check {
              display: grid;
              grid-template-columns: 1.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
              gap: 25px;

              .name {
                display: flex;
                align-items: center;
                gap: 10px;
              }
            }
          }
        }

        .actions {
          margin: 10px 0;
          padding: 0;
          gap: 10px;

          .button {
            display: flex;
            gap: 5px;
            width: 150px;
          }

          .MuiButtonBase-root {
            text-transform: capitalize;
            background-color: $primary-color;
            height: 25px;
            width: 100px;
          }

          .MuiChip-root {
            background-color: white;
            width: fit-content;
            height: 20px;
          }

          .loader {
            height: fit-content;
          }

          .check {
            color: $primary-color;
          }

          .close {
            color: red;
          }
        }
      }
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      padding: 0 50px;

      .input {
        height: 150px;
        width: 100%;

        .MuiFormControl-root {
          width: 100%;
        }

        .MuiInputBase-root {
          width: 90%;
          border-radius: 20px;

          textarea {
            height: 100px;
          }

          fieldset {
            border-color: $primary-color;
            box-shadow: 0 2.5px 5px 0 rgba(0, 0, 0, 0.25);
          }
        }
      }

      .buttons {
        display: flex;
        flex-direction: column;
        gap: 25px;

        .MuiButtonBase-root {
          text-transform: capitalize;
          width: 150px;
          box-shadow: 0 2.5px 5px 0 rgba(0, 0, 0, 0.25);
        }

        .override {
          color: orange;
          border-color: orange;
        }

        .manual {
          color: white;
          background-color: $primary-color;
        }

        .loader {
          height: auto;
        }
      }
    }

    .templates {
      display: flex;
      gap: 15px;
      padding: 0 50px;
      margin-top: 20px;

      .MuiButtonBase-root {
        min-width: 35px;
      }

      .toggle {
        font-size: 18px;
      }

      .translate {
        display: flex;
        align-items: center;
        gap: 10px;

        .loader {
          height: fit-content;
        }
      }
    }
  }
}

.campaign-actions {
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;

  .current-actions {
    display: flex;
    gap: 10px;

    .MuiChip-root {
      border-color: red;

      .MuiChip-label {
        color: red;
      }

      .MuiChip-icon {
        opacity: 0;
        width: 0;
        transition: all 0.3s ease-in-out;
        color: red;
      }

      &:hover {
        .MuiChip-label {
          color: red;
          padding: 3px 7px;
        }

        .MuiChip-icon {
          opacity: 1;
          width: 20px;
        }
      }

      .MuiChip-icon:hover {
        color: black;
      }
    }
  }

  .add-actions {
    position: absolute;
    top: 110%;
    left: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5px;
    z-index: 100;
    min-width: 120px;
    background-color: white;
    transform: translateY(-10px);
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease-in-out;

    &.open {
      transform: translateY(0);
      opacity: 1;
      pointer-events: auto;
    }
  }

  .action-chip {
    .MuiChip-label {
      opacity: 0;
      width: 0;
      padding: 0 3px;
      transition: all 0.3s ease-in-out;
    }
  }

  .action-chip:hover,
  .action-chip.active {
    .MuiChip-label {
      opacity: 1;
      width: 40px;
      padding: 0 5px;
    }
  }
}

.campaign-audit {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;

    h3 {
      margin: 0;
    }

    .MuiSvgIcon-root {
      color: $primary-color;
    }
  }

  .MuiTableContainer-root {
    height: 225px;
  }
}

.phone-numbers {
  .header {
    display: flex;
    align-items: center;
    gap: 10px;

    h3 {
      margin: 0;
    }

    .MuiButtonBase-root {
      padding: 5px;
    }

    .MuiSvgIcon-root {
      color: $primary-color;
      height: 25px;
      width: 25px;
    }

    .loader {
      height: max-content;
    }
  }

  .phone {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    gap: 10px;
    padding: 10px 0;
    min-height: 25px;
    width: fit-content;
    border-bottom: 1px solid lightgray;

    .hover:hover {
      cursor: pointer;
    }

    .MuiSvgIcon-root {
      color: $primary-color;
      align-self: center;
    }

    a {
      color: black;
      text-decoration: none;
    }

    a:hover {
      color: $primary-color;
    }

    .chips {
      min-width: 300px;
      display: flex;
      flex-wrap: wrap;

      .MuiChip-root {
        min-width: 75px;
        height: 25px;
        margin-right: 10px;
      }
    }

    .actions {
      .MuiButtonBase-root {
        color: orange;
        border-color: orange;
        text-transform: none;
        height: 25px;
        margin-right: 10px;
      }

      .MuiButton-contained {
        color: white;
        background-color: $primary-color;
      }
    }
  }
}

.MuiTooltip-popper {
  white-space: pre-line;

  .MuiTooltip-tooltip {
    font-size: 14px;
  }
}

.import-modal {
  .MuiDialogContent-root {
    .MuiTableCell-root {
      padding: 10px;
    }
  }
}

@media (max-width: 900px) {
  .campaign {
    width: 90%;

    .campaign-header {
      .patient-info {
        p {
          font-size: 14px;
        }
      }
    }

    .referred-providers {
      .provider {
        .departments {
          .address {
            font-size: 12px;
          }
        }
      }
    }
  }
}
