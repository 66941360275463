@import '../../styles/variables';

.user-avatar {
  width: 30px !important;
  height: 30px !important;

  &:hover {
    cursor: pointer;
  }

  &.unassigned {
    background-color: lightgray;

    .MuiSvgIcon-root {
      color: white !important;
      padding: 0 !important;
    }
  }
}
